import React, { useState } from 'react';
import './index.css'
import { baseURL } from '../../config/baseURL'
import InputMask from 'react-input-mask';

//Import de imagens
import imgLogo from '../../assets/img-login/imgLogo45x45.png'
import imgLogosm from '../../assets/img-login/imgLogo32x32.png'
import FormAddress from './components/FormAddress/index'
import ModalConfirmFormaNewAssociate from './components/ModalConfirmFormaNewAssociate/index'

//Componente Preload
import { PreloaderRing, PreloaderRingMin } from '../../components/Preload/index'

export default function Associacao({ history }) {

    //VARIAVEIS DOS DADOS CADASTRAIS
    const [residentialAddress, setResidentialAddress] = useState({
        cep: '',
        rua: '',
        bairro: '',
        cidade: '',
        estado: '',
        numero: '',
        complemento: '',
    })
    const [businessAddress, setBusinessAddress] = useState({
        cep: '',
        rua: '',
        bairro: '',
        cidade: '',
        estado: '',
        numero: '',
        complemento: '',
    })
    const [conjugeNome, setConjugeNome] = useState('')
    const [conjugeNasc, setConjugeNasc] = useState('')
    const [conjugeCPF, setConjugeCPF] = useState('')
    const [conjugeRG, setConjugeRG] = useState('')
    const [num_func, setNum_func] = useState('')
    const [nome, setNome] = useState('')
    const [nomeTitular, setNomeTitular] = useState('')
    const [rgTitular, setRgTitular] = useState('')
    const [cpfTitular, setCpfTitular] = useState('')
    const [nacionalidade, setNacionalidade] = useState('')
    const [orgaoEspedidorRg, setOrgaoEspedidorRg] = useState('')
    const [sexo, setSexo] = useState('')
    const [rg, setRg] = useState('')
    const [vinc_pm, setVinc_pm] = useState('')
    const [cpf, setCpf] = useState('')
    const [dt_nasc, setDt_nasc] = useState('')
    const [estado_civil, setEstado_civil] = useState('')
    const [situac_efetivo, setSituac_efetivo] = useState('')
    const [email, setEmail] = useState('')
    const [tel1, setTel1] = useState('')
    const [tel2, setTel2] = useState('')
    const [graduacao, setGraduacao] = useState('')
    const [nomeDepen, setNomeDepen] = useState('')
    const [sexoDepen, setSexoDepen] = useState('')
    const [rgDepen, setRgDepen] = useState('')
    const [cpfDepen, setCpfDepen] = useState('')
    const [nascimentoDepen, setNascimentoDepen] = useState('')
    const [parentescoDepen, setParentescoDepen] = useState('')
    const [arrayDepen, setArrayDepen] = useState([])

    const [preloadRingCadas, setPreloadRingCadas] = useState('d-none mr-4')
    const [btnCadastrarModal, setBtnCadastrarModal] = useState('btn btn-primary d-block')
    const [classAlertDepen, setclassAlertDepen] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [msgAlert, setmsgAlert] = useState('')
    const [classAlert, setclassAlert] = useState('alert alert-danger form-group col-md-12 d-none')
    const [classAlertCampoVazio, setclassAlertCampoVazio] = useState('alert alert-danger form-group col-md-12 mt-5 d-none')
    const [btnCadastrar, setBtnCadastrar] = useState('btn btn-primary mr-3 d-block')

    function limparDados() {
        setNum_func('')
        setNome('')
        setSexo('')
        setRg('')
        setNacionalidade('')
        setVinc_pm('')
        setCpf('')
        setDt_nasc('')
        setEstado_civil('')
        setSituac_efetivo('')
        setEmail('')
        setTel1('')
        setTel2('')
        setGraduacao('')
        setArrayDepen([])
        setNomeDepen('')
        setParentescoDepen('')
        setNascimentoDepen('')
        setCpfDepen('')
        setRgDepen('')
        setOrgaoEspedidorRg('')
        setNascimentoDepen('')
        setConjugeCPF('')
        setConjugeNasc('')
        setConjugeNome('')
        setConjugeRG('')
        setResidentialAddress({
            cep: '',
            rua: '',
            bairro: '',
            cidade: '',
            estado: '',
            numero: '',
            complemento: '',
        })
        setBusinessAddress({
            cep: '',
            rua: '',
            bairro: '',
            cidade: '',
            estado: '',
            numero: '',
            complemento: '',
        })
        // setclassAlertBuscSocio('alert alert-danger d-none')
        setclassAlertCampoVazio('alert alert-danger d-none')
        // setBtnAtualizar('btn btn-success mr-3 d-none')
        // setBtnCadastrar('btn btn-primary mr-3 d-block')
    }


    function addDepend() {
        setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 d-none')

        if (nomeDepen === '' || parentescoDepen === '' || nascimentoDepen === '') {
            setmsgAlert('Peencha todos os campos dos depedentes.')
            return setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 ')
        }

        setArrayDepen(
            [...arrayDepen,
            {
                id: arrayDepen.length + 1,
                nome: nomeDepen,
                dt_nascimento: nascimentoDepen,
                parentesco: parentescoDepen,
                cpfDepen: cpfDepen,
                rgDepen: rgDepen,
                sexoDepen: sexoDepen,
            }
            ]
        )
    }

    function removeDepen(idDepen) {
        let arrayDependente

        arrayDependente = arrayDepen.filter((f) => {
            return f.id !== idDepen
        })

        setArrayDepen(arrayDependente)

        setNomeDepen('')
        setParentescoDepen('')
        setNascimentoDepen('')

    }

    function formateDate(date) {
        if (!date) {
            return '';
        }
        let dataNasc = (date)?.toString()
        let dia, mes, ano
        dia = dataNasc.substring(8, 10)
        mes = dataNasc.substring(5, 7)
        ano = dataNasc.substring(0, 4)
        return `${dia}/${mes}/${ano}`
    }

    async function salvarDados() {
        setPreloadRingCadas('d-block mr-4')

        //VERIFICAÇÃO, SE EXISTEM CAMPOS OBRIGATORIOS EM BRANCO
        if (num_func !== '' && nome !== '' && sexo !== '' && rg !== '' && nacionalidade !== ''
            && vinc_pm !== '' && orgaoEspedidorRg != '' && cpf !== '' && dt_nasc !== '' && estado_civil !== ''
            && situac_efetivo !== '' && tel1 !== '' && graduacao !== '' && residentialAddress?.numero !== ''
            && residentialAddress?.cep !== '' && residentialAddress?.rua !== '' && residentialAddress?.bairro !== ''
            && residentialAddress?.cidade !== '' && residentialAddress?.estado !== '') {

            /*VALIDAÇÃO SIMPLES DE CPF, FUTURAMENTE INSERIR UMA VALIDAÇÃO MAIS FORTE */
            if (cpf.length !== 14) {
                setPreloadRingCadas('d-none mr-4')
                setmsgAlert(`Erro: CPF inválido, verifique se está seguindo o formato xxx.xxx.xxx-xx.`)
                return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            const response = await fetch(`${baseURL}socio/proposta-associacao`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    num_func: Number(num_func),
                    rg: Number(rg),
                    nome: nome?.toUpperCase(),
                    cpf: cpf?.toUpperCase(),
                    email: email?.toUpperCase(),
                    sexo: sexo?.toUpperCase(),
                    vinc_pm,
                    dt_nasc: dt_nasc?.toUpperCase(),
                    graduacao: graduacao?.toUpperCase(),
                    estado_civil: estado_civil?.toUpperCase(),
                    situac_efetivo: situac_efetivo?.toUpperCase(),
                    tel1: tel1,
                    tel2: tel2,
                    conjugeNome: conjugeNome?.toUpperCase(),
                    conjugeNasc,
                    conjugeCPF,
                    conjugeRG,
                    nomeTitular: nomeTitular.toUpperCase(),
                    rgTitular,
                    cpfTitular,
                    nacionalidade: nacionalidade?.toUpperCase(),
                    orgaoEspedidorRg: orgaoEspedidorRg?.toUpperCase(),
                    conjuge_nome: conjugeNome.toUpperCase(),
                    conjuge_nasc: conjugeNasc,
                    conjuge_cpf: conjugeCPF,
                    conjuge_rg: conjugeRG,
                    nome_titular: nomeTitular.toUpperCase(),
                    rg_titular: rgTitular,
                    cpf_titular: cpfTitular,
                    orgao_emissor_rg: orgaoEspedidorRg.toUpperCase(),
                    enderecos: [
                        {
                            cep: residentialAddress?.cep,
                            logradouro: residentialAddress?.rua?.toUpperCase(),
                            uf: residentialAddress?.estado?.toUpperCase(),
                            cidade: residentialAddress?.cidade?.toUpperCase(),
                            bairro: residentialAddress?.bairro?.toUpperCase(),
                            numero: Number(residentialAddress?.numero),
                            complemento: residentialAddress?.complemento?.toUpperCase(),
                            situacao: 'ATIVO',
                            type: 'RESIDENCIAL',
                        }, {
                            cep: businessAddress?.cep,
                            logradouro: businessAddress?.rua?.toUpperCase(),
                            uf: businessAddress?.estado?.toUpperCase(),
                            cidade: businessAddress?.cidade?.toUpperCase(),
                            bairro: businessAddress?.bairro?.toUpperCase(),
                            numero: Number(businessAddress?.numero),
                            complemento: businessAddress?.complemento?.toUpperCase(),
                            situacao: 'ATIVO',
                            type: 'COMERCIAL',
                        }
                    ],
                    dependentes: arrayDepen.map((depen) => {
                        return {
                            nome: (depen.nome)?.toUpperCase(),
                            parentesco: (depen.parentesco)?.toUpperCase(),
                            dt_nascimento: depen.dt_nascimento,
                            cpf_depen: depen.cpfDepen,
                            rg_depen: depen.rgDepen,
                            sexo_depen: depen.sexoDepen,
                        }
                    })
                })
            })

            const respJSON = await response.json()

            //VERIFICAÇÃO SE JÁ EXISTE SOCIO COM O NF CADASTRADO 
            if (await respJSON.erro) {
                setPreloadRingCadas('d-none mr-4')
                setmsgAlert(respJSON.erro)
                return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            setPreloadRingCadas('d-none mr-4')
            setmsgAlert('Sua solicitação foi enviada para análise, entraremos em contato!')
            limparDados()
            return setclassAlertCampoVazio('alert alert-success form-group col-md-12 mt-3 d-block cadSucesso')

        } else {
            setPreloadRingCadas('d-none mr-4')
            setmsgAlert(`Erro: Existem campos obrigatórios em branco.`)
            return setclassAlertCampoVazio('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
        }
    }

    return (
        <div className="containerEditAssociateNoScroll">
            <div className="containerEditAssociate">
                <div className="content">
                    <div className="row">
                        <div className="col-md-12">
                            {/*TITULO PARA TELAS PEQUENAS*/}
                            <div className="col-md-12 d-lg-none d-md-none d-sm-block">
                                <h5 className="text-center text-white mb-4">
                                    <img className="img-fluid" src={imgLogosm} alt="" />
                                    <strong className="mx-1"> FORMULÁRIO DE PROPOSTA PARA ASSOCIAÇÃO </strong>
                                    <img className="img-fluid" src={imgLogosm} alt="" />
                                </h5>
                            </div>
                            {/*TITULO PARA TELAS GRANDES*/}
                            <div className="col-md-12 d-none d-md-block">
                                <h2 className="text-center text-white mb-4">
                                    <img className="img-fluid" src={imgLogo} alt="" />
                                    <strong className="mx-3"> FORMULÁRIO DE PROPOSTA PARA ASSOCIAÇÃO </strong>
                                    <img className="img-fluid" src={imgLogo} alt="" />
                                </h2>
                            </div>

                        </div>

                    </div>

                    <div className="containerForm container-fluid p-3 rounded-lg border border-danger mb-3">
                        <h5>Dados Pessoais</h5>
                        <form className="needs-validation">
                            <div className="form-row border-bottom  border-danger mb-3 pb-3">
                                <div className="form-group col-md-5">
                                    <label htmlFor="nome">Nome<span className="text-danger" >*</span></label>
                                    <input required id="nome" type="text" className="form-control" placeholder=""
                                        maxLength="50" value={nome} onChange={event => setNome(event.target.value)} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="inputEstado_civ">Estado Civil<span className="text-danger" >*</span></label>
                                    <select id="inputEstado_civ" className="form-control" value={estado_civil} onChange={event => setEstado_civil(event.target.value)}>
                                        <option value="" >Escolher...</option>
                                        <option value="CASADO (A)" >Casado (a)</option>
                                        <option value="DIVORCIADO (A)" >Divorciado (a)</option>
                                        <option value="SOLTEIRO (A)" >Solteiro (a)</option>
                                        <option value="VIUVO (A)" >Viuvo (a)</option>
                                        <option value="OUTROS" >Outros</option>
                                        <option value="MARIATAL" >Mariatal</option>
                                        <option value="NÃO INFORMA" >Não Informa</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="nome">Nacionalidade<span className="text-danger" >*</span></label>
                                    <input required id="nome" type="text" className="form-control" placeholder=""
                                        maxLength="50" value={nacionalidade} onChange={event => setNacionalidade(event.target.value)} />
                                </div>
                                <div className="form-group col-md-1">
                                    <label htmlFor="inputEstado">Sexo<span className="text-danger" >*</span></label>
                                    <select id="inputEstado" className="form-control" value={sexo} onChange={event => setSexo(event.target.value)} >
                                        <option value="">-</option>
                                        <option value="M">M</option>
                                        <option value="F">F</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="dt_nasc">Nascimento<span className="text-danger" >*</span></label>
                                    <input required id="dt_nasc" type="date" className="form-control" placeholder="DD/MM/AAAA" value={dt_nasc} onChange={event => setDt_nasc(event.target.value)} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="cpf">CPF<span className="text-danger " >*</span></label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        value={cpf}
                                        onChange={event => setCpf(event.target.value)}
                                    >
                                        {inputProps => <input {...inputProps} required id="cpf" type="text" className="form-control" placeholder="XXX.XXX.XXX-XX" />}
                                    </InputMask>
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="rg">RG<span className="text-danger" >*</span></label>
                                    <input required id="rg" type="number" className="form-control" placeholder="" value={rg} onChange={event => setRg(event.target.value)} />
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="nome">Órgão Expedidor<span className="text-danger" >*</span></label>
                                    <input required id="nome" type="text" className="form-control" placeholder=""
                                        maxLength="50" value={orgaoEspedidorRg} onChange={event => setOrgaoEspedidorRg(event.target.value)} />
                                </div>

                                <div className="form-group col-md-4">
                                    <label htmlFor="email">E-mail</label>
                                    <input id="email" type="email" className="form-control" placeholder="" value={email} onChange={event => setEmail(event.target.value)} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="telefone1">Celular<span className="text-danger" >*</span></label>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        value={tel1}
                                        onChange={event => setTel1(event.target.value)}
                                    >
                                        {inputProps => <input {...inputProps} required id="cpf" type="text" className="form-control" placeholder="(XX) XXXXX-XXXX" />}
                                    </InputMask>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="telefone2">Telefone</label>
                                    <InputMask
                                        mask="(99) 9999-9999"
                                        value={tel2}
                                        onChange={event => setTel2(event.target.value)}
                                    >
                                        {inputProps => <input {...inputProps} required id="cpf" type="text" className="form-control" placeholder="(XX) XXXX-XXXX" />}
                                    </InputMask>
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="nf">NF<span className="text-danger" >*</span></label>
                                    <input required id="nf" type="number" className="form-control" placeholder=""
                                        value={num_func} onChange={event => setNum_func(event.target.value)} />
                                </div>
                                <div className="form-group col-md-1">
                                    <label htmlFor="vinc_pm">Vínculo<span className="text-danger" >*</span>   </label>
                                    <input required id="vinc_pm" type="number" className="form-control" placeholder="N°" value={vinc_pm} onChange={event => setVinc_pm(event.target.value)} />
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="situac_efetivo">Situação Vínculo Militar<span className="text-danger" >*</span></label>
                                    <select id="situac_efetivo" className="form-control" value={situac_efetivo} onChange={event => setSituac_efetivo(event.target.value)}>
                                        <option value="">Escolher...</option>
                                        <option value="ATIVA" >Ativa</option>
                                        <option value="REFORMADO (A)" >Reformado (a)</option>
                                        <option value="RESERVA" >Reserva</option>
                                        <option value="FALECIDO">Falecido</option>
                                    </select>
                                </div>
                                <div className="form-group col-md-4">
                                    <label htmlFor="graduacao">Posto / Graduação / Pensionista<span className="text-danger" >*</span></label>
                                    <select id="graduacao" className="form-control" value={graduacao} onChange={event => setGraduacao(event.target.value)} >
                                        <option value="">Escolher...</option>
                                        <option value={'CORONEL'} >Coronel</option>
                                        <option value={'TENENTE-CORONEL'} >Tenente-Coronel</option>
                                        <option value={'MAJOR'} >Major</option>
                                        <option value={'CAPITÃO'} >Capitão</option>
                                        <option value={'1º TENENTE'} >1º Tenente</option>
                                        <option value={'2º TENENTE'} >2º Tenente</option>
                                        <option value={'SUB TENENTE'} >Sub Tenente</option>
                                        <option value={'ALUNO OFICIAL'} >Aluno Oficial</option>
                                        <option value={'1º SARGENTO'} >1º Sargento</option>
                                        <option value={'2º SARGENTO'} >2º Sargento</option>
                                        <option value={'3º SARGENTO'} >3º Sargento</option>
                                        <option value={'CABO'} >Cabo</option>
                                        <option value={'SOLDADO'} >Soldado</option>
                                        <option value={'ALUNO SOLDADO'} >Aluno Soldado</option>
                                        <option value={'PENSIONISTA'} >Pensionista</option>
                                        <option value={graduacao} >{graduacao}</option>
                                    </select>
                                </div>
                            </div>

                            <div className={graduacao === 'PENSIONISTA' ? " m-0 p-0" : " m-0 p-0 d-none"}>
                                <h5>Militar Titular</h5>

                                <div className="form-row border-bottom mb-3 pb-3 border-danger">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="nomeViuv">Nome</label>
                                        <input required id="nomeViuv" type="text" className="form-control" value={nomeTitular}
                                            onChange={event => setNomeTitular(event.target.value)} maxLength="50" />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="cpfViuv">RG</label>
                                        <input required id="cpfViuv" type="text" className="form-control"
                                            value={rgTitular} maxLength="14" onChange={event => setRgTitular(event.target.value)} />
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="cpfViuv">CPF</label>
                                        <InputMask
                                            mask="999.999.999-99"
                                            value={cpfTitular}
                                            onChange={event => setCpfTitular(event.target.value)}
                                        >
                                            {inputProps => <input {...inputProps} required id="cpf" type="text" className="form-control" placeholder="XXX.XXX.XXX-XX" />}
                                        </InputMask>
                                    </div>
                                </div>
                            </div>

                            <FormAddress
                                address={residentialAddress}
                                setAddress={setResidentialAddress}
                                setclassAlert={setclassAlert}
                                msgAlert={msgAlert}
                                classAlert={classAlert}
                                label={'Endereço Residencial'}
                            />

                            <FormAddress
                                isNotRequired
                                address={businessAddress}
                                setAddress={setBusinessAddress}
                                setclassAlert={setclassAlert}
                                msgAlert={msgAlert}
                                classAlert={classAlert}
                                label={'Endereço Profissional, Comercial ou Trabalho'}
                            />

                            <h5>Cônjuge ou Companheiro(a) do(a) Proponente</h5>
                            <div className="form-row border-bottom mb-3 pb-3 border-danger">
                                <div className="form-group col-md-4">
                                    <label htmlFor="nome">Nome</label>
                                    <input required id="nome" type="text" className="form-control" placeholder=""
                                        maxLength="50" value={conjugeNome} onChange={event => setConjugeNome(event.target.value)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="dt_nasc">Nascimento</label>
                                    <input required id="dt_nasc" type="date" className="form-control" placeholder="DD/MM/AAAA" value={conjugeNasc} onChange={event => setConjugeNasc(event.target.value)} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label htmlFor="cpf">CPF</label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        value={conjugeCPF}
                                        onChange={event => setConjugeCPF(event.target.value)}
                                    >
                                        {inputProps => <input {...inputProps} required id="cpf" type="text" className="form-control" placeholder="XXX.XXX.XXX-XX" />}
                                    </InputMask>
                                    {/* <input required id="cpf" type="text" className="form-control" placeholder="XXX.XXX.XXX-XX"
                                        maxLength="14" value={conjugeCPF} onChange={event => setConjugeCPF(event.target.value)} /> */}
                                </div>
                                <div className="form-group col-md-2">
                                    <label htmlFor="rg">RG</label>
                                    <input required id="rg" type="number" className="form-control" placeholder="" value={conjugeRG} onChange={event => setConjugeRG(event.target.value)} />
                                </div>
                            </div>

                            <h5>Dependentes</h5>
                            <div className="border-bottom mb-3 pb-3 border-danger">
                                <div className="form-row align-items-end">
                                    <div className="form-group col-md-9">
                                        <label htmlFor="nomeDepen">Nome</label>
                                        <input onClick={() => setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 d-none')}
                                            type="text" id="nomeDepen" className="form-control" placeholder="" maxLength="50"
                                            value={nomeDepen} onChange={event => setNomeDepen(event.target.value)} />
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label htmlFor="nascimentoDepen">Nascimento</label>
                                        <input onClick={() => setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 d-none')}
                                            id="nascimentoDepen" type="date" className="form-control" placeholder="DD/MM/AAAA"
                                            value={nascimentoDepen} onChange={event => setNascimentoDepen(event.target.value)} />
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label htmlFor="parentesco">Parentesco</label>
                                        <select onClick={() => setclassAlertDepen('alert alert-danger form-group col-md-12 mt-2 d-none')}
                                            id="parentesco" className="form-control" value={parentescoDepen}
                                            onChange={event => setParentescoDepen(event.target.value)}>
                                            <option >Escolher...</option>
                                            <option>Avô (ó)</option>
                                            <option>Esposo (a)</option>
                                            <option>Filho (a)</option>
                                            <option>Irmão (a)</option>
                                            <option>Mâe</option>
                                            <option>Pai</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-1">
                                        <label htmlFor="inputEstado">Sexo</label>
                                        <select id="inputEstado" className="form-control" value={sexoDepen} onChange={event => setSexoDepen(event.target.value)} >
                                            <option value="">-</option>
                                            <option value="M">M</option>
                                            <option value="F">F</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-4">
                                        <label htmlFor="cpf">CPF</label>
                                        <InputMask
                                            mask="999.999.999-99"
                                            value={cpfDepen}
                                            onChange={event => setCpfDepen(event.target.value)}
                                        >
                                            {inputProps => <input {...inputProps} required id="cpf" type="text" className="form-control" placeholder="XXX.XXX.XXX-XX" />}
                                        </InputMask>
                                        {/* <input required id="cpf" type="text" className="form-control" placeholder="XXX.XXX.XXX-XX"
                                            maxLength="14" value={cpfDepen} onChange={event => setCpfDepen(event.target.value)} /> */}
                                    </div>
                                    <div className="form-group col-md-3">
                                        <label htmlFor="rg">RG</label>
                                        <input required id="rg" type="number" className="form-control" placeholder="" value={rgDepen} onChange={event => setRgDepen(event.target.value)} />
                                    </div>
                                    <div className="form-group col-md-1">
                                        <button type="button" onClick={addDepend} className="btn btn-success" style={{ width: '100%' }}>
                                            <i className="fas fa-user-plus"></i>
                                        </button>
                                    </div>
                                </div>

                                <div className={classAlertDepen} role="alert" >
                                    <strong> {msgAlert}</strong>
                                </div>

                                <div className="col" id="insertDepen">
                                    <div style={{ overflow: 'auto' }} >

                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Cod.</th>
                                                    <th scope="col">Nome</th>
                                                    <th scope="col">Nascimento</th>
                                                    <th scope="col">Parentesco</th>
                                                    <th scope="col">Remover</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {arrayDepen.map(depen => {
                                                    return (
                                                        <tr key={depen.id} id="linhaDepen">
                                                            <td>{depen.id}</td>
                                                            <td>{depen.nome}</td>
                                                            <td>{formateDate(depen.dt_nascimento)}</td>
                                                            <td>{depen.parentesco}</td>
                                                            <td>   <button type="button" onClick={() => removeDepen(depen.id)} className="btn btn-danger mx-0 my-0" > <i className="fas fa-user-minus"></i> </button> </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                    </div>

                                </div>

                            </div>

                            <div className="col-md-8 mb-4">
                                <div className={classAlertCampoVazio} role="alert" >
                                    <strong>  {msgAlert} </strong>
                                </div>
                            </div>


                            <div className="form-row justify-content-end">
                                <i className={preloadRingCadas}> <PreloaderRing /> </i>
                                <button
                                    onClick={() => {
                                        setclassAlertCampoVazio('d-none')
                                        setBtnCadastrarModal('btn btn-primary d-block')
                                    }}
                                    type="button"
                                    className={btnCadastrar}
                                    data-toggle="modal"
                                    data-target="#exampleModal">
                                    Enviar
                                </button>

                                <button onClick={limparDados} className="btn btn-danger" type="button">Limpar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <ModalConfirmFormaNewAssociate
                arrayDepen={arrayDepen}
                bairro={residentialAddress?.bairro}
                cep={residentialAddress?.cep}
                cidade={residentialAddress?.cidade}
                complemento={residentialAddress?.complemento}
                estado={residentialAddress?.estado}
                numero={residentialAddress?.numero}
                rua={residentialAddress?.rua}
                cpf={cpf}
                dt_nascimento={dt_nasc}
                email={email}
                estado_civil={estado_civil}
                graduacao={graduacao}
                nome={nome}
                situac_efetivo={situac_efetivo}
                tel1={tel1}
                tel2={tel2}
                vinc_pm={vinc_pm}
                btnCadastrarModal={btnCadastrarModal}
                formateDate={formateDate}
                num_func={num_func}
                rg={rg}
                dt_nasc={dt_nasc}
                sexo={sexo}
                residentialAddress={residentialAddress}
                businessAddress={businessAddress}
                conjugeNome={conjugeNome}
                conjugeNasc={conjugeNasc}
                conjugeCPF={conjugeCPF}
                conjugeRG={conjugeRG}
                nacionalidade={nacionalidade}
                orgaoEspedidorRg={orgaoEspedidorRg}
                onConfirm={salvarDados}
            />
        </div>

    )

}