import React, { useState } from 'react';
import { PreloaderRingMin } from '../../../../components/Preload/index'

export default function FormAddress({
    label,
    address,
    setAddress,
    classAlert,
    setclassAlert,
    isNotRequired,
}) {
    const [preloadRingMinCep, setPreloadRingMinCep] = useState('col-md-auto d-none')
    const [msgAlert, setmsgAlert] = useState('')

    async function buscarCep() {
        setPreloadRingMinCep('col-md-auto d-block')

        console.log('cep para busca: ', address);


        try {
            const response = await fetch(`https://viacep.com.br/ws/${address?.cep}/json/`)
            const respJSON = await response.json()

            if (await respJSON.erro) {
                setPreloadRingMinCep('col-md-auto d-none')
                setmsgAlert(`CEP não encontrado`)
                return setclassAlert('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
            }

            setPreloadRingMinCep('col-md-auto d-none')
            setAddress(currentState => ({
                ...currentState,
                rua: respJSON.logradouro,
                bairro: respJSON.bairro,
                cidade: respJSON.localidade,
                estado: respJSON.uf,
            }))
            // setBairro(respJSON.bairro)
            // setCidade(respJSON.localidade)
            // setEstado(respJSON.uf)
            // setRua(respJSON.logradouro)
        } catch (error) {
            setPreloadRingMinCep('col-md-auto d-none')
            setmsgAlert(`CEP não encontrado ou inválido`)
            return setclassAlert('alert alert-danger form-group col-md-12 d-block cadErro mt-3')
        }

    }

    return (
        <>
            <h5>{label}</h5>
            <div className="form-row border-bottom mb-3 pb-3 border-danger">
                <div className="form-group col-md-3">
                    <div className="row justfy-content-start">
                        <div className="col-md-auto mr-n4"> <label htmlFor="cep">CEP{!isNotRequired && <span className="text-danger" >*</span>} </label> </div>
                        <div className={preloadRingMinCep}>  <PreloaderRingMin /> </div>
                    </div>
                    <div className="input-group ">
                        <input required id="cep" onClick={() => setclassAlert('alert alert-danger form-group col-md-12 d-none')}
                            type="text" className="form-control" value={address?.cep}
                            placeholder="Apenas números" aria-label="Recipient's username"
                            aria-describedby="button-addon2" onChange={(data) => {
                                data.persist();
                                setAddress(currentState => ({ ...currentState, cep: data?.target?.value }))
                            }}
                        />
                        <div className="input-group-append">
                            <button onClick={() => buscarCep(address?.cep, setAddress)} className="btn btn-success" type="button" id="button-addon2">Buscar</button>
                        </div>
                    </div>
                </div>

                <div className="form-group col-md-7">
                    <label htmlFor="rua">Logradouro{!isNotRequired && <span className="text-danger" >*</span>}</label>
                    <input required id="rua" type="text" className="form-control" placeholder=""
                        maxLength="60" value={address.rua} onChange={event => {
                            event.persist();
                            setAddress(currentState => ({ ...currentState, rua: event?.target?.value }))
                        }} />
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="numero">N°{!isNotRequired && <span className="text-danger" >*</span>}</label>
                    <input required type="number" id="numero" className="form-control" placeholder="" value={address?.numero} onChange={event => {
                        event.persist();
                        setAddress(currentState => ({ ...currentState, numero: event?.target?.value }))
                    }} />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="bairro">Bairro{!isNotRequired && <span className="text-danger" >*</span>}</label>
                    <input required type="text" id="bairro" className="form-control" placeholder="" value={address?.bairro} onChange={event => {
                        event.persist();
                        setAddress(currentState => ({ ...currentState, bairro: event?.target?.value }))
                    }} />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="cidade">Município{!isNotRequired && <span className="text-danger" >*</span>}</label>
                    <input required type="text" id="cidade" className="form-control" placeholder="" value={address?.cidade} onChange={event => {
                        event.persist();
                        setAddress(currentState => ({ ...currentState, cidade: event?.target?.value }))
                    }} />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="uf">UF{!isNotRequired && <span className="text-danger" >*</span>}</label>
                    <select id="uf" className="form-control" value={address?.estado} onChange={event => {
                        event.persist();
                        setAddress(currentState => ({ ...currentState, estado: event?.target?.value }))
                    }}>
                        <option>Escolher...</option>
                        <option value="">Selecione</option>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espirito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                    </select>
                </div>

                <div className="form-group col-md-12">
                    <label htmlFor="complemento">Complemento</label>
                    <input id="complemento" type="text" className="form-control" placeholder=""
                        maxLength="60" value={address?.complemento} onChange={event => {
                            event.persist();
                            setAddress(currentState => ({ ...currentState, complemento: event?.target?.value }))
                        }} />
                </div>

                <div className={classAlert} role="alert" >
                    {msgAlert}
                </div>

            </div>
        </>
    )
}