import React, { useState } from 'react';
import './index.css'
import { baseURL } from '../../config/baseURL'

//Import de imagens
import imgLogo from '../../assets/img-login/imgLogo45x45.png'
import imgLogosm from '../../assets/img-login/imgLogo32x32.png'

//Componente Preload
import { PreloaderRing } from '../../components/Preload/index'

export default function Login({ history }) {

    const [num_func, setNum_func] = useState('')
    const [senha, setSenha] = useState('')
    const [preloadRing, setPreloadRing] = useState({ display: 'none' })
    const [alertUserSenha, setAlertUserSenha] = useState('d-none')
    const [msgErro, setMsgErro] = useState('')
    const [footerSpan, setFooterSpan] = useState('')
    const [typeInput, setTypeInput] = useState('password')

    async function handleSubmit(event) {

        setPreloadRing({ display: 'block' })
        event.preventDefault()

        const response = await fetch(`${baseURL}authenticate-socio`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                num_func: num_func,
                senha: senha
            })
        })

        if (response.status === 404) {
            setPreloadRing({ display: 'none' })
            setFooterSpan('d-none')
            setAlertUserSenha('alert alert-danger col-md-12 my-0 d-block mr-5')
            return setMsgErro('Erro ao se conectar com o servidor, entre em contato com o administrador do sistema.')
        }

        const respJSON = await response.json()

        if (respJSON.erro) {
            setPreloadRing({ display: 'none' })
            setFooterSpan('d-none')
            setAlertUserSenha('alert alert-danger col-md-12 my-0 d-block mr-5')
            setMsgErro(respJSON.erro)
        } else {
            setPreloadRing({ display: 'none' })
            localStorage.setItem('token', respJSON.token)
            localStorage.setItem('id', respJSON.socio.id)
            localStorage.setItem('nome', respJSON.socio.nome)
            localStorage.setItem('sexo', respJSON.socio.sexo)
            localStorage.setItem('graduacao', respJSON.socio.graduacao)
            localStorage.setItem('num-func', respJSON.socio.num_func)
            respJSON.socio.force_senha === 'S' ? history.push('/alterar-senha-login') : history.push('/painel/home')
        }
    }

    return (
        <div className="containerEdit">
            <div className="row">
                <div className="col-md-12">

                    {/*TITULO PARA TELAS PEQUENAS*/}
                    <div className="col-md-12 d-lg-none d-md-none d-sm-block">
                        <h5 className="text-center text-white mb-4">
                            <img className="img-fluid" src={imgLogosm} alt="" />
                            <strong className="mx-1"> ÁREA DO SÓCIO</strong>
                            <img className="img-fluid" src={imgLogosm} alt="" />
                        </h5>
                    </div>
                    {/*TITULO PARA TELAS GRANDES*/}
                    <div className="col-md-12 d-none d-md-block">
                        <h2 className="text-center text-white mb-4">
                            <img className="img-fluid" src={imgLogo} alt="" />
                            <strong className="mx-3"> ÁREA DO SÓCIO</strong>
                            <img className="img-fluid" src={imgLogo} alt="" />
                        </h2>
                    </div>

                    <div className="row">
                        <div className="col-md-6 mx-auto">

                            <div className="card rounded-5">

                                <div className="card-header">
                                    <h3 className="mb-0 text-light">Login</h3>
                                </div>

                                <div className="card-body">

                                    <form onSubmit={handleSubmit} className="needs-validation"
                                        id="formLogin" method="POST">
                                        <div className="form-group">
                                            <label className="text-light" htmlFor="validationCustom03">Número Funcional</label>
                                            <input type="text"
                                                className="form-control"
                                                id="validationCustom03"
                                                required
                                                value={num_func}
                                                onChange={event => setNum_func(event.target.value)}
                                                onClick={() => {
                                                    setFooterSpan('')
                                                    setAlertUserSenha('alert alert-danger col-md-12 mt-3 d-none mr-5')
                                                }}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label className="text-light" htmlFor="senhaInput" >Senha</label>

                                            <div className="input-group">
                                                <input type={typeInput}
                                                    className="form-control"
                                                    id="senhaInput"
                                                    value={senha}
                                                    required
                                                    onChange={event => { setSenha(event.target.value) }}
                                                    onClick={() => {
                                                        setFooterSpan('')
                                                        setAlertUserSenha('alert alert-danger col-md-12 mt-3 d-none mr-5')
                                                    }}
                                                />

                                                <div className="input-group-append">
                                                    <button className="btn" type="button" style={{ backgroundColor: '#e8f0fe' }} id="basic-addon2"
                                                        onClick={() => {
                                                            typeInput === 'password' ? setTypeInput('text') : setTypeInput('password')

                                                        }}
                                                    >
                                                        <i class="fas fa-eye"></i>
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row mt-4 align-items-center justify-content-between px-3">
                                            <button onClick={() => history.push('/associacao')} className="btn btn-warning btn-lg float-left" id="btnLogin">  Desejo me associar </button>
                                            <div className='row'>
                                                <div style={preloadRing} > <PreloaderRing /> </div>
                                                <button onClick={() => setAlertUserSenha('d-none')} type="submit" className="ml-3 mr-3 btn btn-danger btn-lg" id="btnLogin">  Entrar </button>
                                            </div>
                                        </div>
                                    </form>


                                </div>

                                <div className="card-footer text-light text-center">
                                    <div className={alertUserSenha} role="alert">
                                        <strong>  {msgErro} </strong>
                                    </div>
                                    <span className={footerSpan} >
                                        <i className="fas fa-question-circle mr-2"></i>
                                        Caro associado, caso este seja seu primeiro acesso, sua <strong> SENHA </strong> será somente os números do seu <strong>CPF</strong>
                                    </span>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>

    )

}