import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'


//importação da páginas
import Login from './pages/Login/index'
import Associacao from './pages/Associacao/index'
import PainelIndex from './pages/Painel/index'
import AuterarSenhaLogin from './pages/Login/AlterarSenhaLogin/index'
import notFound from './pages/NoFound/notFound'

import { PrivateRoute } from './auth/PrivateRoute'




export default function Reoutes() {

    return (
        <BrowserRouter>

            <Switch>

                <Route path="/" exact component={Login} />
                <Route path="/associacao" component={Associacao} />
                <PrivateRoute path="/alterar-senha-login" component={AuterarSenhaLogin} />
                <PrivateRoute path="/painel" component={PainelIndex} />
                <Route path="*" component={notFound} />

            </Switch>

        </BrowserRouter>
    )

}