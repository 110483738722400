import React from "react"

export default function ModalConfirmFormaNewAssociate({
    nome,
    num_func,
    rg,
    cpf,
    dt_nasc,
    sexo,
    email,
    tel1,
    tel2,
    vinc_pm,
    estado_civil,
    graduacao,
    situac_efetivo,
    conjugeNome,
    conjugeNasc,
    conjugeCPF,
    conjugeRG,
    arrayDepen,
    formateDate,
    btnCadastrarModal,
    nacionalidade,
    orgaoEspedidorRg,
    residentialAddress,
    businessAddress,
    onConfirm,
}) {
    return (
        <div className="modal fade" id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-xl modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 style={{ color: 'black' }} className="modal-title" id="exampleModalLabel"> <strong> CONFIRME AS INFORMAÇÕES ABAIXO </strong></h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4> Dados Pessoais:</h4>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <strong>NOME:</strong> {nome} <br />
                                    <strong>ESTADO CIVIL:  </strong> {estado_civil} <br />
                                    <strong>NACIONALIDADE:  </strong> {nacionalidade} <br />
                                    <strong>SEXO:  </strong> {sexo} <br />
                                    <strong>NASCIMENTO:  </strong> {formateDate(dt_nasc)} <br />
                                    <strong>CPF:</strong> {cpf} <br />
                                    <strong>RG:  </strong> {rg} <br />
                                    <strong>ÓRGÃO EXPEDIDOR:  </strong> {orgaoEspedidorRg} <br />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <strong>E-MAIL:  </strong> {email} <br />
                                    <strong>CELULAR:  </strong> {tel1} <br />
                                    <strong>TELEFONE:  </strong> {tel2} <br />
                                    <strong>NF:  </strong> {num_func} <br />
                                    <strong>VÍNCULO:</strong> {vinc_pm} <br />
                                    <strong>SITUAÇÃO MILITAR:  </strong> {situac_efetivo} <br />
                                    <strong>POSTO / GRADUAÇÃO / PENSIONISTA:  </strong> {graduacao} <br />
                                </div>

                                <div className="pt-3 col-md-12 border-top border-dark">
                                    <h4>Endereço Residencial:</h4>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <strong>LOGRADOURO:</strong> {residentialAddress?.rua} <br />
                                    <strong>BAIRRO:  </strong> {residentialAddress?.bairro} <br />
                                    <strong>UF:  </strong> {residentialAddress?.estado} <br />
                                    <strong>COMPLEMENTO:  </strong> {residentialAddress?.complemento} <br />
                                </div>
                                <div className="col-md-6 mb-4">
                                    <strong>N°:</strong> {residentialAddress?.numero} <br />
                                    <strong>MUNICÍPIO:</strong> {residentialAddress?.bairro} <br />
                                    <strong>CEP:  </strong> {residentialAddress?.cep} <br />
                                </div>

                                <div className="pt-3 col-md-12 border-top border-dark">
                                    <h4>Endereço Profissional, Comercial ou Trabalho:</h4>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <strong>LOGRADOURO:</strong> {businessAddress?.rua} <br />
                                    <strong>BAIRRO:  </strong> {businessAddress?.bairro} <br />
                                    <strong>UF:  </strong> {businessAddress?.estado} <br />
                                    <strong>COMPLEMENTO:  </strong> {businessAddress?.complemento} <br />
                                </div>
                                <div className="col-md-6 mb-4">
                                    <strong>N°:</strong> {businessAddress?.numero} <br />
                                    <strong>MUNICÍPIO:</strong> {businessAddress?.bairro} <br />
                                    <strong>CEP:  </strong> {businessAddress?.cep} <br />
                                </div>

                                <div className="pt-3 col-md-12 mb-3 border-top border-dark">
                                    <h4>Cônjuge ou Companheiro(a) do(a) Proponente:</h4>
                                </div>
                                <div className="col-md-6 mb-4">
                                    <strong>NOME:</strong> {conjugeNome} <br />
                                    <strong>NASCIMENTO:  </strong> {formateDate(conjugeNasc)} <br />
                                </div>

                                <div className="col-md-6 mb-4">
                                    <strong>CPF:  </strong> {conjugeCPF} <br />
                                    <strong>RG:  </strong> {conjugeRG} <br />
                                </div>

                                <div className="pt-3 col-md-12 mb-3 border-top border-dark">
                                    <h4>Depedentes:</h4>
                                </div>
                                <div className="col-md-12">

                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th style={{ color: 'black' }} scope="col">Nome</th>
                                                <th style={{ color: 'black' }} scope="col">Nascimento</th>
                                                <th style={{ color: 'black' }} scope="col">Parentesco</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!!arrayDepen && arrayDepen.map(depen => {
                                                return (
                                                    <tr key={depen.id} >
                                                        <td style={{ color: 'black' }}>{depen.nome}</td>
                                                        <td style={{ color: 'black' }}>{formateDate(depen.dt_nascimento)}</td>
                                                        <td style={{ color: 'black' }}>{depen.parentesco}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal">Retornar</button>
                        <button onClick={onConfirm} type="button" className={btnCadastrarModal} data-dismiss="modal">Confirmar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}